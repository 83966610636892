//Navbar
.top-navbar{
    padding: $grid-gutter-width 0;
    @include media-breakpoint-up(lg){
        padding: $grid-gutter-width;
    }
    .navbar-container{
        align-items: center;

    }

    //key-search
    .key-search{
        position: relative;
        width: 100%;
        height: 50px;
        border-bottom: 2px solid darken($border-color, 3%);
        &:before{
            position: absolute;
            height: 2px;
            width: 40px;
            content: '';
            background-color: $primary;
            left: 0;
            bottom: -2px;

        }
        .form-control{
            background-color: transparent;
            border: 0;
            transition: 0.5s ease;
            color: $body-color;
            @include font-size(.9375rem);
            padding-right: 0;
            padding-left: 20px;
            box-shadow: none;
            height: 50px;
            font-weight: 300;
            color: $body-color;
            padding-left: 50px;
            &::placeholder{
                color: $body-color;;
            }
        }
        button[type="submit"]{
            color: $primary;
            position: absolute;
            @include font-size(1.5rem);
            display: inline-flex;
            align-items: center;
            font-weight: normal;
            background-color: transparent;
            border: 0;
            top: 0;
            padding: 0;
            width: 40px;
            height: 50px;
            line-height: 50px;
            outline: none;
            &:focus{
                box-shadow: none;
                outline: 0;
            }
        }
    }

    .navbar-right{
        .nav{
            padding: 0;
            margin: -7.5px;
            justify-content: flex-end;
            .nav-item{
                list-style: none;
                .nav-link{
                    padding: 7.5px;
                   .icon{
                        height: 40px;
                        width: 40px;
                        border-radius: 50rem;
                        color: $white;
                        background-color: rgba($primary, 1);
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        i{
                            @include font-size(1.25rem);
                            line-height: 0;
                        }
                   }
                }
            }
            @include media-breakpoint-down(md){
                display: none;
            }
        }

    }
}