/* timeline  block style */
.timeline {
  > ul {
    padding: 0;
    margin: 0;
    > li {
      list-style: none;
      & + li {
        margin-top: $grid-gutter-width + 0.625;
      }

      .time {
        position: relative;
        font-weight: 500;
        @include font-size(0.75rem);
        letter-spacing: 0.2px;
        &:before {
          font-family: "bootstrap-icons";
          content: "\f293";
        }
      }

      .intro-img {
        height: 50px;
        width: 50px;
        border-radius: 50rem;
        overflow: hidden;
        position: relative;
        background-color: darken($body-bg, 1%);
        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          font-family: "bootstrap-icons";
          transform: translate(-50%, -50%);
          @include font-size(1.375rem);
        }
        img {
          position: relative;
        }
      }
      .intro-text {
        @include font-size(0.8375rem);
        font-weight: normal;
        h4 {
          @include font-size(0.9375rem);
          margin: 5px 0;
        }
        .meta {
          display: flex;
          padding-left: 0;
          margin: 0;
          flex-wrap: wrap;
          > li {
            list-style: none;
            & + li {
              margin-left: 10px;
              &:before {
                display: inline-block;
                content: "\f287";
                font-family: "bootstrap-icons";
                margin-right: 10px;
                text-align: center;
                vertical-align: middle;
                line-height: 0;
                opacity: 0.75;
                transform: scale(0.3);
              }
            }
          }
        }

        .badge {
          font-weight: 500;
          font-style: normal;
          padding: 0.375rem 0.9375rem;
          @include font-size(0.75rem);
          @each $color, $value in $theme-colors {
            &.bg-light-#{$color} {
              background-color: rgba($value, 0.2);
              color: $value;
            }
          }
        }

        .btn {
          padding: 0.3125rem 0.625rem;
          border-radius: 0.25rem;
          i {
            @include font-size(1.25rem);
            line-height: 0;
          }
        }
      }

      &.package {
        .intro-img {
          &:before {
            content: "\f45c";
            color: $success;
          }
        }
      }
      &.category {
        .intro-img {
          &:before {
            content: "\f2ce";
            color: $warning;
          }
        }
      }
      &.article {
        .intro-img {
          &:before {
            content: "\f36b";
            color: $primary;
          }
        }
      }
      &.blog {
        .intro-img {
          &:before {
            content: "\f363";
            color: $danger;
          }
        }
      }
      &.menu {
        .intro-img {
          &:before {
            content: "\f467";
            color: $info;
          }
        }
      }

      &.review {
        .intro-img {
          &:before {
            content: "\f262";
            color: $warning;
          }
        }
      }

      &.booking {
        .intro-img {
          &:before {
            content: "\f196";
            color: $success;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg){
    position: relative;
    &:before {
      position: absolute;
      left: 108px;
      top: 0;
      bottom: 0;
      content: "";
      background-color: $border-color;
      width: 3px;
    }

    >ul{
      >li{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .time{
          flex: 0 0 150px;
          max-width: 150px;
          padding-right: 70px;
          text-align: right;
          &:before{
            height: 40px;
            width: 40px;
            background-color: $white;
            border-radius: 50rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 20px;
            top: calc(50% - 20px);
            @include font-size(1.25rem);
          }
        }
        .intro-img{
          flex: 0 0 50px;
        }
        .intro-text{
        flex: 0 0 calc(100% - 200px);
        max-width: calc(100% - 200px);
        padding-left: $grid-gutter-width/2 + 0.25;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .col {
          flex: 0 0 auto;
        }
        }
      }
    }

  }

  @include media-breakpoint-down(lg){
    >ul{
      >li{
        flex-direction: column;
        position: relative;
        padding-left: 60px;
        .time{
          &:before{
            margin-right: 5px;
          }
        }
        .intro-img{
          position: absolute;
          left: 0;
          top: 0;
        }
        .meta{
          padding: 5px 0;
        }
        .btn-primary.light{
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          visibility: hidden;
        }
        &:hover{
          .btn-primary.light{
            opacity: 1;
            visibility: visible;
            
          }

        }
      }
    }

  }
}
