/*Pagination Style */
.pagination {
  margin: 0;

  .page-link {
    position: relative;
    font-weight: 600;
    border-radius: 50rem;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease all;

    &:hover {
      box-shadow: $pagination-focus-box-shadow;
    }
  }

  .page-item {

    &:first-child,
    &:last-child {
      .page-link {
        @include font-size(1.5rem);
        background-color: $white;
        box-shadow: 0 2px 2px rgba($black, 0.075);
        border-radius: 50%;

        &:hover,
        &:focus{
          background-color: $pagination-hover-bg;
        }
      }
    }

    &.active .page-link {
      box-shadow: $pagination-focus-box-shadow;
    }
  }

  @include media-breakpoint-up(md) {
    .page-link {
      height: 40px;
      width: 40px;
      margin: 0 5px;
      @include font-size(1rem);
    }

    .page-item {
      &:first-child {
        .page-link {
          margin-right: 30px;
        }
      }

      &:last-child {
        .page-link {
          margin-left: 30px;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .page-link {
      height: 35px;
      width: 35px;
      margin: 0 2.5px;
    }

    .page-item {
      &:first-child {
        .page-link {
          margin-right: 15px;
        }
      }

      &:last-child {
        .page-link {
          margin-left: 15px;
        }
      }
    }
  }
}