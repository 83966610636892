/* general style for html and body */

::selection {
    background-color: $primary;
    color: $white;
}

html {
    @include font-size(16px);
}

html,
body {
    height: 100%;
}

body {
    min-width: 320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: none;
}

video {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: top;
}
img{
    max-width: 100%;
}
/* reset default form styles */

input[type="text"],
input[type="search"],
input[type="password"],
input[type="submit"],
input[type="reset"],
button,
textarea,
input[type="email"] {
    -webkit-appearance: none;
    border-radius: 0;
}

iframe {
    vertical-align: top;
}

strong,
b {
    font-weight: bold;
}

p:last-child {
    margin-bottom: 0;
}

.wrapper {
    width: 100%;
    overflow: hidden;
    height: 100%;
    display: table;
    position: relative;
    table-layout: fixed;
}

/* Common-box style */

.common-box {
    padding-top: $global-md-space;
    padding-bottom: $global-md-space;
    position: relative;

    @include media-breakpoint-up(sm) {
        padding-top: $global-space;
        padding-bottom: $global-space;
    }

    @include media-breakpoint-up(lg) {
        padding-top: $global-top-space;
        padding-bottom: $global-bottom-space;
    }
}

/* Common module Style */

.common-module {
    margin-bottom: $grid-gutter-width*2;
    display: inline-block;
    width: 100%;

    &[class^="bg-"],
    &[class*=" bg-"] {
        padding: $grid-gutter-width/2;

        @include media-breakpoint-up(md) {
            padding: $grid-gutter-width;
        }
    }

    &.shadow {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }

    h3 {
        @include font-size(1.375rem);
    }

    .module-title {
        margin-bottom: 20px;

        h2,
        h3 {
            margin-bottom: 0;
        }

        h2 {
            font-weight: bold;
            @include font-size(1.5rem);
        }

        &.has-border {
            padding-bottom: 15px;
            border-bottom: 1px solid $border-color;

            &.bolded-border {
                border-width: 2px;
            }
        }
    }
}


.font-weight-medium{
    font-weight: $font-weight-medium;
}
.font-weight-semibold{
    font-weight: $font-weight-semibold;
}

/*-- Rating style --*/

i[class^="ratings_"],
i[class*=" ratings_"] {
    display: inline-block;
    line-height: 1;
    font-family: "FontAwesome";
    font-style: normal;
    position: relative;
    white-space: nowrap;
    letter-spacing: 4px;
    color: $secondary;
    @include font-size($font-size-base);

    &:before {
        content: "\f006 \f006 \f006 \f006 \f006";
    }

    &:after {
        content: "\f005 \f005 \f005 \f005 \f005";
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
    }
}

.ratings__0:after {
    width: 0;
}

.ratings__1:after {
    width: 20%;
}

.ratings__2:after {
    width: 40%;
}

.ratings__3:after {
    width: 60%;
}

.ratings__4:after {
    width: 80%;
}

.ratings__5:after {
    width: 100%;
}

//title
.title{
    margin-bottom: $grid-gutter-width;
}
