/*Sidebar Search*/

.recent-article {
    ul {
        padding: 0;
        overflow: hidden;
        margin: -10px 0;

        >li {
            list-style: none;
            padding-top: 10px;
            padding-bottom: 10px;
            @include clearfix();
        }
    }

    .item {
        .sub-text {
            @include font-size(.75rem);
            font-weight: 600;
        }

        h4 {
            margin: 0;
            @include font-size($font-size-base);
            line-height: 1.5;

            a {
                color: $headings-color;
                transition: color 0.25s ease;
                text-decoration: none;
            }
        }
    }

    &.with-thumbnail {
        .item {

            .intro-img,
            .intro-text {
                float: left;
            }

            .intro-img {
                width: 35%;
                overflow: hidden;

                img {
                    transition: transform 0.25s ease;
                }
            }

            .intro-text {
                width: 65%;
                padding-left: 15px;
            }
        }
    }

    .item:hover{
        img{
            transform: scale(1.2,1.2);
        }

         h4 a{
            color: $primary;
        }

    }
}