/* button styles */

.btn {
    i{
        vertical-align: middle;
        line-height: 1;
    }
    &.btn-link {
        line-height: 1;
        padding: 0;
        &.text-md {
            @include font-size(1.125rem);
        }
        &.text-lg {
            @include font-size(1.25rem);
        }
        &.text-xl {
            @include font-size(1.5rem);
        }
    }
}
.btn-primary{
    color: $white;
    &:hover,
    &:focus{
        color: $white;
    }
}

@mixin button-shadow-variant($color, $color-hover: $white) {
    box-shadow: 0 10px 30px 0 rgba($color, 0.34);
}

@each $color, $value in $theme-colors {
    .btn-#{$color}:not(.bg-white).shadow {
        @include button-shadow-variant($value, $value);
    }
    .btn-#{$color}.light{
        background-color: rgba($value, 0.15);
        color: $value;
        border-color: transparent;
        &:hover{
            background-color: $value;
            color: $white;
        }
    }
}





.btn.bg-white.shadow {
    box-shadow: 0 10px 30px 0 rgba($primary, 0.15);
}