/* accordion  Style*/

.classic-accordion {
    &.shadow {
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
    }

    .card {
        border: 0;
        background: transparent;

        &:not(:first-child) {
            padding-top: $grid-gutter-width;
        }

        &:not(:last-child) {
            padding-bottom: $grid-gutter-width;
        }

        .card-header {
            background-color: transparent;
            border: 0;
            padding: 0;

            [data-bs-toggle="collapse"] {
                margin: 0;
                @include font-size(1rem);
                padding-left: 30px;
                position: relative;
                line-height: 1.5;
                cursor: pointer;

                &:after {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: "bootstrap-icons";
                    content: "\f4d3";
                    @include font-size(1.25rem);
                    font-weight: normal;
                    color: $primary;
                }

                &[aria-expanded="true"]{
                    &:after {
                        content: "\f2c6";
                        color: rgba($body-color, 0.74);
                    }
                }
            }
        }

        &+.card {
            margin-top: 0;
            border-top: 1px solid darken($body-bg, 8%);
        }

        .card-body {
            padding: 15px 0 0 30px;
        }
    }

    @include media-breakpoint-up(md) {

        &[class^="bg-"],
        &[class*=" bg-"] {
            padding: $grid-gutter-width;
        }

        .card {
            &:not(:first-child) {
                padding-top: $grid-gutter-width;
            }

            &:not(:last-child) {
                padding-bottom: $grid-gutter-width;
            }
        }
    }

    @include media-breakpoint-down(sm) {

        &[class^="bg-"],
        &[class*=" bg-"] {
            padding: $grid-gutter-width/2;
        }

        .card {
            &:not(:first-child) {
                padding-top: 15px;
            }

            &:not(:last-child) {
                padding-bottom: 15px;
            }
        }
    }
}