.elevationChart {
    width: 100%;
    height: 100%;
}

.chartContainer {
    background-image: url("../../../assets/images/bg.PNG");
    height: 380px;
    background-size: cover;
    padding-top: 20px;
    border: 1px solid #ccc;
}
