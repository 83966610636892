/* Actions Bar style */
.action-bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px $grid-gutter-width/-2;
  margin-right: $grid-gutter-width/-2;
  >*{
  	// padding-left: $grid-gutter-width/2;
  	// padding-right: $grid-gutter-width/2;
    padding: 5px $grid-gutter-width/2;
  }
  .title {
    margin-bottom: 0;
    h1 {
      margin: 0;
      @include font-size(1.5rem);
    }
  }

  .action-slot {
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: $grid-gutter-width/-4;
	  padding-left: 0;
      > li {
        list-style: none;
        padding: $grid-gutter-width/4;
      }
    }

    .btn {
      border-width: 0;
      border-radius: 50rem;
      padding: 0.4rem 1.5rem;
      font-weight: 500;
      @include font-size(0.75rem);
      outline: 0;
      box-shadow: none;

      &:not(.btn-primary) {
        background-color: $white;
      }
      &.btn-primary {
        color: $white;
        min-width: 150px;
        i {
          @include font-size(0.875rem);
        }
      }

      i {
        line-height: 0;
        margin-right: 5px;
        @include font-size(0.875rem);
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &:not(.btn-primary) {
        i {
          height: 18px;
          width: 18px;
          border-radius: 50rem;
          &:before {
            line-height: 18px;
          }
        }
        @each $color, $value in $theme-colors {
          &.btn-#{$color} {
            color: $value;
            i {
              background-color: $value;
              color: $white;
            }
          }
        }
      }

      @each $color, $value in $theme-colors {
        &.btn-#{$color} {
          &:hover {
            background-color: $value;
            color: $white;
            box-shadow: 0 0 15px rgba($value, 0.1);
          }
          &:not(.btn-primary):hover {
            i {
              background-color: $white;
              color: $value;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
.fixed-position {
  .action-bar {
    position: fixed;
    top: 0;
    z-index: 9;
	padding-top: 10px;
	padding-bottom: 10px;
	&:before{
		left: -9999px;
		right: -9999px;
		top: 0;
		position: absolute;
		z-index: -1;
		bottom: 0;
		content: '';
		background-color: $body-bg;
		box-shadow: 0 0 4px rgba($black, 0.2);
	}
  }
}
}

@include media-breakpoint-down(md){
	.action-bar{
		flex-direction: column;
    align-items: flex-start;
	}
}

.custom-form>*+*{
  padding-top: $grid-gutter-width;
}


.ant-steps{
  font-family: $font-family-base;
  .ant-steps-item{
    .ant-steps-item-icon{
      svg{
        vertical-align: initial;
      }
      .ant-steps-icon{
        top: -1.5px;
      }
    }
    
  .ant-steps-item-title{
    &:after{
      background-color: darken($border-color, 5%);
      height: 2px;
    }

  }
  }
  .ant-steps-item-content .ant-steps-item-title{
    @include font-size(.9375rem);
    font-weight: 500;

  }

}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background-color: darken($border-color, 5%);

}

.ant-steps-item-wait .ant-steps-item-icon:not(:hover){
  border-color: darken($border-color, 10%);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
  color: $headings-color;
}