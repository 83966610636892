/* login register Page Style*/
.login-page {
  height: 100vh;
  background-color: $white;
  position: relative;
  overflow: hidden;
}

.login-module-row{
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  >*{
    max-width: 50%;
    flex: 0 0 50%;
  }

  @include media-breakpoint-down(lg){
    flex-direction: column;
    height: auto;
    >*{
      max-width: 100%;
      flex: 0 0 100%;
    }
    .login-module-col:first-child{
      display: flex;
      justify-content: center;
    }
    
.has-background{
  justify-content: center;
  height: 45vh;
}

  }

  @include media-breakpoint-up(lg){
    .login-module-col:first-child{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    
.has-background{
  display: flex;
  align-items: center;
  color: $primary;
  padding: 15vh;
  padding-left: 0;
}

  }
}

.has-background{
  display: flex;
  align-items: center;
  color: $primary;
  svg{
    width: 100%;
    height: 100%;
  }
}


//login-module
.login-module {
  position: relative;
  z-index: 2;
  //background: linear-gradient(to top, rgba($white, 0.95) 20%, rgba($white, 0.0001));
  //padding: $grid-gutter-width/2 + .625;
  max-width: 450px;
  padding: $grid-gutter-width;
  @include media-breakpoint-up(sm) {
    min-width: 450px;
    padding: 2.5rem;
    //box-shadow: 0 0 50px rgba($primary, 0.15);
    border-radius: 4px;
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
  .login-module-header {
    .logo{
      position: relative;
      overflow: hidden;
      max-width: 220px;
      display: block;
      &:before{
        background-size: auto 20%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        content: "";
        display: block;
        width: 100%;
        z-index: -1;
        padding-top: 34%;
      }
      >img{
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        object-fit: contain;
      }
    }
  }
  .login-module-body {
    padding-top: 1.25rem;
    .form-group {
      margin-bottom: 1.25rem;
    }

    .form-control {
      border-width: 2px;
      background-color: rgba($white, .85);
      min-height: 54px;
      box-shadow: none;
      border-radius: 0;
      outline: none;
      letter-spacing: 1px;
      font-weight: normal;
      @include font-size(1rem);
    }

    .input-group {
      position: relative;
      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        @include font-size(1.375rem);
        line-height: 0;
        z-index: 5;
      }

      i.bi-eye-slash  {
        cursor: pointer;
      }
      input[type="text"] ~ i.bi-eye-slash:before{
          content: "\f321";
      }
    }

    .forget-password {
      font-size: 0.8375rem;
      font-weight: 500;
      letter-spacing: 1px;
      padding-top: 5px;
      text-decoration: underline;
      text-align: right;
      a {
        text-decoration: none;
        color: #24282b;
      }
    }

    .submit-btn{
      margin-top: 1.875rem;
    }

    .btn-primary{
      padding: .625rem 3rem;
      color: $white;
      @include font-size(1rem);
      box-shadow: 0 0 20px rgba($primary, 0.5);
      border-radius: 50rem;


    }
  }
  .login-module-footer {
  }
}

canvas {
  display: block;
}

#particles-js {
  width: 100%;
  height: 100%;
  background-image: url("");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1;
}