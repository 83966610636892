/* preloader styles */

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    white-space: nowrap;
    text-align: center;
    background: $gray-700;
    z-index: 9999;
    transition: all linear .3s;
    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        margin-right: -0.25em;
    }
    &.loaded {
        opacity: 0;
        visibility: hidden;
        z-index: -1;
    }
    .holder {
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
    }
}

.sp {
    width: 70px;
    height: 70px;
    clear: both;
    margin: 0
}


/* Spinner Wave */
.sp-wave {
    border-radius: 50%;
    position: relative;
    opacity: 1;
}
.sp-wave:before, .sp-wave:after  {
    content:'';
    border: 3px rgba($white, 0.72) solid;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    left:0px;
}
.sp-wave:before {
    transform: scale(1,1);
    opacity: 1;
    -webkit-animation: spWaveBe 0.9s infinite linear;
    animation: spWaveBe 0.9s infinite linear;
}
.sp-wave:after {
    transform: scale(0,0);
    opacity: 0;
    -webkit-animation: spWaveAf 0.9s infinite linear;
    animation: spWaveAf 0.9s infinite linear;
}
@keyframes spWaveAf {
    from { transform: scale(0.5,0.5); opacity: 0; }
    to { transform: scale(1,1); opacity: 1; }
}
@keyframes spWaveBe {
    from { -webkit-transform: scale(1,1); opacity: 1; }
    to { -webkit-transform: scale(1.5,1.5); opacity: 0; }
}






